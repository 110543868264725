<template>
  <div
    class="container"
    v-title
    :data-title="$t('i18n.annualVolumeStatistics')"
  >
    <div id="outer-title">{{ $t("i18n.annualVolumeStatistics") }}</div>
    <div class="chartContain" v-loading="loading">
      <jl-chart v-if="!loading" name="mulOption" :columns="option"></jl-chart>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import jlChart from "../../components/chart";
var allData = {};
export default {
  name: "ResultStatic",
  components: {
    jlChart,
  },
  setup: (props, { emit }) => {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const state = reactive({
      loading: true,
      option: {
        timeline: "",
        xData: [],
        type: "bar",
        series: [],
        legend: [],
        mulData: [],
      },
    });

    const init = () => {
      let nowYear = new Date().getFullYear();
      state.option.timeline = [];
      for (let i = 2017; i <= nowYear; i++) {
        state.option.timeline.push(i);
        initData(i);
      }
      proxy.$config.month.map((m) => {
        state.option.xData.push(t("i18n." + m));
      });
      setOption();
    };

    const initData = async (year) => {
      let obj = { year: year };
      let { data } = await proxy.$api.maintenance.getResultStatic(obj);
      allData[year] = [];
      data.map((item) => {
        let d = item.data.substring(1, item.data.length - 1).split(",");
        allData[year].push({ data: d });
      });
      setDataByYear();
    };

    const setOption = () => {
      let legend = ["maintenanceUnits", "repairUnits", "trappedUnits"];
      legend.map((one) => {
        state.option.legend.push(t("i18n." + one));
        state.option.series.push({
          name: t("i18n." + one),
          type: "bar",
          markPoint: {
            data: [
              { type: "max", name: t("i18n.maximum") },
              { type: "min", name: t("i18n.minimum") },
            ],
          },
          markLine: {
            data: [{ type: "average", name: t("i18n.average") }],
          },
        });
      });
    };

    const setDataByYear = () => {
      state.option.mulData = [];
      for (let key in allData) {
        state.option.mulData.push({
          title: { text: key + t("i18n.annualVolumeStatistics") },
          series: allData[key],
        });
      }
      state.loading = false;
    };

    init();

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style scoped>
.chartContain {
  height: 700px;
}
@media screen and (max-width: 768px) {
  .chartContain {
    height: 500px;
  }
}
</style>
